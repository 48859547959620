/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Header from "./NavBar"
import "./layout.css"
import Footer from "./Footer"
import StyledBackgroundSection from "./background"
import { withStyles } from "@material-ui/core"
import '../../i18n'

const styles = theme => ({
  container: {
    flex: 1,
    padding: '70px 10% 50px 10%',
    [theme.breakpoints.down('xs')]: {
      padding: '70px 5% 30px 5%'
    }
  },
  root: {
    display: 'flex',
    minHeight: '100vh'
  },
  app: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  }

})

const Layout = ({ children, classes }) => {
  return (
    <StyledBackgroundSection>
      <div className={classes.root}>
        <Header/>
        <div className={classes.app}>
          <main className={classes.container}>
            {children}
          </main>
          <footer>
            <Footer/>
          </footer>
        </div>
      </div>
    </StyledBackgroundSection>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default (withStyles(styles))(Layout)
