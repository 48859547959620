import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  withStyles,
  Grid,
  Hidden,
  IconButton,
  CssBaseline,
  Toolbar,
  AppBar,
  Drawer,
  Grow,
  Typography,
  Tooltip,
} from "@material-ui/core"
import { Link } from 'gatsby-theme-material-ui'
import { Home, Web, School, Menu, Clear } from '@material-ui/icons'
import _ from 'lodash'
import Button from "@material-ui/core/Button"
import UsEnFlag from '../images/us-en-flag.jpg'
import FrFlag from '../images/fr-flag.jpg'
import { useTranslation } from "react-i18next"

const styles = {
  link: {
    textDecoration: 'none',
    color: '#dddddd',
    display: 'flex',
    alignItems: 'center',
    margin: '5px 10px',
    padding: ' 5px 10px',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  activeLink: {
    textDecoration: 'none',
    borderBottom: '1px solid red'
  },
  navBar: {
    position: 'fixed',
    backgroundColor: 'rgba(0,0,0,0.9)',
    zIndex: 20,
    padding: '10px 50px',
    boxShadow: '0px 10px 20px 0px black'
  },
  icon: {
    marginRight: '5px'
  },
  appBar: {
    backgroundColor: 'rgba(0,0,0,0.9)',
    boxShadow: ' 0px 10px 20px 0px black'
  },
  drawer: {
    backgroundColor: 'rgba(0,0,0,0.9)',
    boxShadow: '10px 0px 20px 0px black'
  },
  flag: {
    height: '20px',
    borderRadius: '50px'
  }
}

const Pages = ({ classes, onClose }) => {
  const { t } = useTranslation("NavBar")

  const pages = [
    {
      to: '/',
      title: t('home'),
      icon: <Home className={classes.icon}/>
    },
    {
      to: '/realisations',
      title: t('realisations'),
      icon: <Web className={classes.icon}/>
    },
    {
      to: '/experiences',
      title: t('experiences'),
      icon: <School className={classes.icon}/>
    }
  ]

  return (
    <Fragment>
      {_.map(pages, (page, id) => (
          <div onClick={onClose} key={id}>
            <Typography>
              <Link
                activeClassName={classes.activeLink}
                to={page.to}
                className={classes.link}
              >
                {page.icon}{page.title}
              </Link>
            </Typography>
          </div>
        )
      )}
    </Fragment>
  )
}

Pages.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  t: PropTypes.func
}

const NavBar = ({ classes }) => {
  const { t, i18n } = useTranslation("NavBar")

  const [open, setOpen] = useState(false)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <Fragment>
      <Hidden smUp>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={classes.appBar}
        >
          <Toolbar>
            <Grow
              in={!open}
              style={{ transformOrigin: '0 0 0' }}
              {...(!open ? { timeout: 1000 } : {})}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
              >
                <Menu />
              </IconButton>
            </Grow>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawer
          }}
        >
          <div>
            <IconButton
              color={'primary'}
              aria-label="close drawer"
              edge="start"
              onClick={handleDrawerClose}
            >
              <Clear />
            </IconButton>
          </div>
          <Button style={{ marginBottom: 10 }} onClick={() => i18n.changeLanguage(i18n.language === 'en' ? 'fr' : 'en') }>
            <img className={classes.flag} src={i18n.language === 'en' ? FrFlag : UsEnFlag} alt={i18n.language === 'en' ? 'English' : 'Français'} />
          </Button>
          <Pages classes={classes} onClose={handleDrawerClose}/>
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Grid container item justify={'flex-end'} className={classes.navBar}>
          <Pages classes={classes} t={t}/>
          <Tooltip title={i18n.language === 'en' ? 'Français' : 'English'} placement={"bottom"} arrow>
            <Button onClick={() => i18n.changeLanguage(i18n.language === 'en' ? 'fr' : 'en') }>
              <img className={classes.flag} src={i18n.language === 'en' ? FrFlag : UsEnFlag} alt={i18n.language === 'en' ? 'English' : 'Français'} />
            </Button>
          </Tooltip>
        </Grid>
      </Hidden>
    </Fragment>
  )
}

NavBar.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles) (NavBar)
