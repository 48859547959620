import React from 'react'
import { Grid, withStyles, Typography } from '@material-ui/core'
import { LinkedIn, Email, Call, GitHub, Room } from '@material-ui/icons'
import { GITHUB, LINKED_IN } from '../constants/index'
import PropTypes from 'prop-types'

const styles = theme => ({
  title: {
    margin: 0,
    color: theme.palette.text.primary,
    textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
  },
  container: {
    backgroundColor: theme.palette.background.secondary,
    padding: '2% 10%',
    borderTop: '1px solid black',
    boxShadow: '0px -10px 20px 0px black',
    [theme.breakpoints.down('xs')]: {
      padding: '2% 5%'
    }
  },
  linkedIn: {
    marginTop: '10px',
    textDecoration: 'none',
    color: theme.palette.text.primary,
    width: 'fit-content',
    padding: '5px',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: '#0e76a8'
    }
  },
  gitHub: {
    marginTop: '10px',
    textDecoration: 'none',
    color: theme.palette.text.primary,
    width: 'fit-content',
    padding: '5px',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: '#3e3e3e'
    }
  },
  contact: {
    marginTop: '10px',
    display: 'flex',
    color: theme.palette.text.primary,
    alignItems: 'center',
    width: 'fit-content',
    padding: '5px',
    borderRadius: '5px',
    margin: 0
  },
  icons: {
    fontSize: 30,
    marginRight: 5
  },
  socialButtons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  img: {
    width: 30,
    marginRight: 5
  },
  text: {
    color: theme.palette.text.secondary
  }
})

const Footer = props => {
  const { classes } = props
  return (
    <Grid className={classes.container} container>
      <Grid item xs={12}>
        <Typography variant={"h4"} className={classes.title}>Contact</Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.socialButtons}>
        <Typography className={classes.contact}><Email className={classes.icons}/>contact@maximepochet.fr</Typography>
        <Typography className={classes.contact}><Call className={classes.icons}/>06 59 00 67 61</Typography>
        <Typography className={classes.contact}><Room className={classes.icons}/>Rognac (13)</Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.socialButtons}>
        <Typography><a href={LINKED_IN} target={'_blank'} rel="noopener noreferrer" className={classes.linkedIn}><LinkedIn className={classes.icons}/><span>LinkedIn</span></a></Typography>
        <Typography><a href={GITHUB} target={'_blank'} rel="noopener noreferrer" className={classes.gitHub}><GitHub className={classes.icons}/><span>GitHub</span></a></Typography>
      </Grid>
      <Grid container justify={"center"} item xs={12}>
        <Typography className={classes.text}>Maxime Pochet © | Made with React and Gatsby</Typography>
      </Grid>
    </Grid>
  )
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired
}

export default (withStyles(styles))(Footer)
